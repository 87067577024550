// /var/www/website2024/admin.siamweeds.com/myapp/src/components/Header.js

import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

import { Menubar } from 'primereact/menubar'; // Import Menubar
import { OverlayPanel } from 'primereact/overlaypanel';
import { Badge } from 'primereact/badge';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Toast } from 'primereact/toast';

import { useDispatch } from 'react-redux';
import { clearFilters, requestClearFilters } from '../redux/slices/filtersSlice';

import {
  faCircleLeft, faRotateRight,
  faTachometerAlt, faCreditCard, faCalendarCheck, faPeopleGroup,
  faUsers, faSpa, faSignOutAlt, faFileInvoice
} from '@fortawesome/free-solid-svg-icons';

import '../css/Header.scss';
import '../css/Header.css';
import { API_URLS } from '../api/api';  // Adjust the path as necessary


const Header = ({ onBack, searchQuery, setSearchQuery, placeholderText, refreshData, handleClearFilters }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const op = useRef(null); // For OverlayPanel

  const [bookingNotifications, setBookingNotifications] = useState([]);
  const token = localStorage.getItem('token');
  const [notificationCount, setNotificationCount] = useState(0); // New state for badge count
  const [userOptions, setUserOptions] = useState([]);
  const userId = localStorage.getItem('user_ID');
  const toast = useRef(null);

  const handleBack = () => {
    if (onBack) {
      onBack(); // If onBack is provided, call it
    } else {
      navigate(-1); // Otherwise, navigate back
    }
  };

  useEffect(() => {
    // Fetch users for dropdown
    fetchUsers();
    fetchBookingNotifications(); // Ensure this is called to fetch initial data
  }, [token]);


  const fetchBookingNotifications = async () => {
    try {
      const response = await axios.get(API_URLS.BOOKING_NOTIFICATIONS, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const formattedData = response.data.map(booking => ({
        ...booking,
        ServiceDate: new Date(booking.ServiceDate).toLocaleDateString('en-GB'), // Changes the date format to DD/MM/YYYY
        ServiceTime: booking.ServiceTime.substr(0, 5), // Trims the ServiceTime to HH:MM format
        CombinedService: {
          ServiceName: booking.ServiceName,
          PriceType: booking.PriceType
        }
      }));
      setBookingNotifications(formattedData);
      setNotificationCount(formattedData.length); // Update badge count based on fetched data

    } catch (error) {
      console.error('Failed to fetch booking notifications:', error);
    }
  };

  // Updated fetchUsers function
  const fetchUsers = async () => {
    try {
      const response = await axios.get(API_URLS.ALL_USERS(userId, '', '2'), {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      if (response.data) {
        const options = response.data.map(user => ({
          label: `${user.first_name} ${user.sur_name}`,
          value: user.user_ID,
        }));
        setUserOptions(options);
      } else {
        throw new Error('No data received');
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      //alert('Failed to fetch employee data. Please try again later.');
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'ailed to fetch employee data. Please try again later.', life: 3000 });
    }
  };

  const employeeDropdownTemplate = (rowData) => {
    return (
      <Dropdown
        value={rowData.EmployeeID}
        options={userOptions}
        onChange={(e) => handleEmployeeChange(e.value, rowData.EmployeeID, rowData)} // Pass user_ID along with rowData
        placeholder="Select Emp."
        optionLabel="label"
      />
    );
  };

  // Custom template to display customer details
  const customerTemplate = (rowData) => {
    return `${rowData.CustomerFirstName} ${rowData.CustomerSurName}, ${rowData.CustomerTelephone}`;
  };

  // Custom template to display date and time
  const dateTimeTemplate = (rowData) => {
    return `${new Date(rowData.ServiceDate).toLocaleDateString('en-GB')} at ${rowData.ServiceTime.substr(0, 5)}`;
  };



  const handleEmployeeChange = async (newEmployeeId, selectedUserId, rowData) => {
    try {
      const response = await axios.put(
        API_URLS.UPDATE_EMPLOYEE(rowData.BookingDetailID),
        { status_ID: newEmployeeId, user_ID: selectedUserId },
        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } }
      );

      // Now that response is defined, you can check its status
      if (response.status === 200) {
        toast.current.show({ severity: 'success', summary: 'Employee Updated', detail: 'The employee has been updated successfully.', life: 3000 });
        // Refresh the booking notifications to reflect the changes
        fetchBookingNotifications();
        refreshData();
      }
    } catch (error) {
      console.error('Error updating employee:', error);
      // Use toast.current instead of toastRef.current
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to update employee. Please try again later.', life: 3000 });
    }
  };




  const renderIcon = (iconData) => {
    return <FontAwesomeIcon icon={iconData} className="p-menuitem-icon" />;
  };

  // Function to generate menu items for Menubar
  const getMenubarItems = () => menuItems.map(item => ({
    ...item,
    icon: renderIcon(item.icon)
  }));


  const menuItems = [
    { label: 'Dashboard', icon: faTachometerAlt, command: () => navigate('/Dashboard') },
    { label: 'Payment', icon: faCreditCard, command: () => navigate('/payment') },
    { label: 'Order', icon: faCalendarCheck, command: () => navigate('/booking') },
    { label: 'Queues', icon: faPeopleGroup, command: () => navigate('/queues') },
    { label: 'Staff', icon: faUsers, command: () => navigate('/Member') },
    { label: 'Product', icon: faSpa, command: () => navigate('/services') },
    {
      label: 'Reports', icon: faFileInvoice, items: [
        { label: 'Order Report', command: () => navigate('/report/booking') },
        // { label: 'Payment Report', command: () => navigate('/report/payment') },
        // { label: 'Service Report', command: () => navigate('/report/service') },
        // { label: 'Customer Report', command: () => navigate('/report/customer') }
      ]
    },
    { label: 'Logout', icon: faSignOutAlt, command: () => navigate('/logout') }
  ];


  const handleNotificationClick = (e) => {
    op.current.toggle(e);
  };

  const handleRefreshData = () => {
    if (location.pathname.includes('report/booking')) {
      console.log("Clearing filters and refreshing data for booking report");
      dispatch(requestClearFilters());
      //handleClearFilters();
    } else {
      refreshData();
    }
  };

  const combinedServiceTemplate = (rowData) => {
    return (
      <div>
        <div>{rowData.CombinedService.ServiceName}</div>
        <div>{rowData.CombinedService.PriceType}</div>
      </div>
    );
  };

  return (
    <>
      <Toast ref={toast} />

      <header className="header">

        <FontAwesomeIcon className="fas back_button" onClick={handleBack} icon={faCircleLeft} /> {/* This will render the back arrow icon */}
        <input
          type="text"
          placeholder={placeholderText || "Type in to search..."}
          className="search-bar"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

        <div className="header-icons">

          {/* Your actual icons */}

          <FontAwesomeIcon className='fas' icon={faRotateRight} onClick={handleRefreshData} /> {/* Modify to call handleRefreshData */}

          <i className="fas fa-bell p-overlay-badge" onClick={handleNotificationClick}>
            <Badge value={notificationCount} severity="danger"></Badge>
          </i>


          <OverlayPanel ref={op} id="overlay_panel" style={{ width: '470px' }} className="overlay-panel-scroll">
            <DataTable value={bookingNotifications} size="small" className="custom-small-datatable p-datatable-sm" scrollable scrollHeight="500px">
              <Column field="BookingDetailID" header="ID" sortable />
              <Column field="BookingID" header="Invoice" sortable />
              <Column field="EmployeeID" header="Employee" body={employeeDropdownTemplate} sortable />
              <Column header="Date & Time" body={dateTimeTemplate} />
              <Column header="Service" body={combinedServiceTemplate} sortable />
              <Column header="Customer" body={customerTemplate} />
              <Column field="StatusName" header="Status" sortable />
            </DataTable>
          </OverlayPanel>

          <i className="fas fa-solid fa-sliders"></i>
        </div>

      </header>

      <Menubar model={getMenubarItems()} />

    </>

  );
};

export default Header;
