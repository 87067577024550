// /var/www/website2024/admin.siamweeds.com/myapp/src/components/CalendarView.js

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/FullCalendarCustomStyles.css';
import '../css/CalendarEvent.scss';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { API_URLS } from '../api/api';

const CalendarView = ({onDateClick, refreshFlag}) => {
  const [events, setEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(API_URLS.EVENTS, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        //console.log('Fetched API data:', response.data);

        // Correct date parsing and event mapping
        const eventMapping = response.data.reduce((acc, detail) => {
          // Ensuring that ServiceDate is a valid ISO string
          const eventDateISO = new Date(detail.ServiceDate).toISOString().split('T')[0];
          if (!acc[eventDateISO]) {
            acc[eventDateISO] = {
              title: `Bookings for ${eventDateISO}`,
              start: eventDateISO + 'T00:00:00', // Ensuring start is set correctly
              end: eventDateISO + 'T23:59:59', // Ensuring end is set correctly
              color: '#007bff',
              serviceTimes: [],
            };
          }
          acc[eventDateISO].serviceTimes.push({
            BookingID: detail.BookingID,
            time: detail.ServiceTime,
            serviceID: detail.ServiceID,
            serviceStatus: detail.ServiceStatus,

            employeeID: detail.EmployeeID,
            EmployeeFirstName: detail.EmployeeFirstName,
            EmployeeSurName: detail.EmployeeSurName,
            EmployeeTelephone: detail.EmployeeTelephone,

            CustomerEmail: detail.CustomerEmail,
            CustomerFirstName: detail.CustomerFirstName,
            CustomerSurName: detail.CustomerSurName,
            CustomerTelephone: detail.CustomerTelephone,

            ServiceName: detail.ServiceName,
            PriceType: detail.PriceType,
            StatusName: detail.StatusName,
          });
          return acc;
        }, {});

        //console.log('Reformatted event data:', eventMapping);
        setEvents(Object.values(eventMapping));
      } catch (error) {
        console.error("Failed to fetch events:", error);
      }
    };

    fetchEvents();
    
  }, [refreshFlag]);

  

  const handleDateSelect = (selectInfo) => {
    onDateClick(selectInfo.dateStr); // Pass the selected date back to the parent component
  };


  const handleEventClick = ({ event }) => {
    setSelectedEvent(event.extendedProps || event);
    setShowModal(true);
  };

  const employeeTemplate = (rowData) => {
    return `${rowData.EmployeeFirstName} ${rowData.EmployeeSurName}, ${rowData.EmployeeTelephone}`;
  };

  const customerTemplate = (rowData) => {
    return `${rowData.CustomerFirstName} ${rowData.CustomerSurName}, ${rowData.CustomerTelephone}, ${rowData.CustomerEmail}`;
  };


  return (

    <main className="main-content">

      <div id="calendar-view">
        <FullCalendar    
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          dateClick={handleDateSelect}
          events={events}
          eventClick={handleEventClick}
          height={550}
        />
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} className="datatable-modal">
        <Modal.Header closeButton>
          <Modal.Title>Event Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedEvent && (
            <div className="datatable">
              <DataTable value={selectedEvent.serviceTimes} className="p-datatable-sm" header="Booking" responsiveLayout="scroll" autoLayout={true}>
                <Column field="BookingID" header="ID" />
                <Column field="time" header="Time" />

                <Column field="ServiceName" header="Service" />
                <Column field="PriceType" header="Type" />
                <Column field="StatusName" header="Status" />

                <Column body={employeeTemplate} header="Employee" />
                <Column body={customerTemplate} header="Customer" />
              </DataTable>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </main>
  );
};

export default CalendarView;
